import React from 'react';
import { Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import useSWR from 'swr';

import TourScreen from "./screens/TourScreen"
import HomeScreen from "./screens/HomeScreen"
import GenericLocalPlayerScreen from './screens/GenericLocalPlayerScreen';
import { VideoNum } from  "./lib/utils" 
import './App.global.css';

interface CustomRouteProps extends RouteProps {
  [key: string]: any;
};

const fetcher = (url) => fetch(url).then((res) => res.json());

const App = () => {

  // xxx move to context
  const { data, error } = useSWR(
    "./en.json",
    fetcher
  );

  const tourStyleClassname = "cadtheme-tour-main-bg";

  const PlayerRoute: React.FC<CustomRouteProps> = (playerProps: CustomRouteProps) : JSX.Element => {
    const {path, title, homeTarget, videoNum, mainStyleClassname}  = playerProps; 
    return <Route exact path={path} render={(props) => <GenericLocalPlayerScreen {...props} data={data} title={title} videoNum={videoNum} mainStyleClassname={mainStyleClassname} homeTarget={homeTarget} /> }   />
  }

  if (error) {
    console.log(error)
    return <>An error has occurred</>
  }
  if (!data) return <>Loading...</>

  return (
    <>
      <Switch>
        <Route exact path="/tour" render={(props) => <TourScreen {...props} data={data} mainStyleClassname={tourStyleClassname}  /> }   />
        
        <PlayerRoute videoNum={VideoNum.GlobalProduction} path="/global-production" title={data.home.video1}  mainStyleClassname="genericplayer-v1-bg" homeTarget="home" /> 
        <PlayerRoute videoNum={VideoNum.Sustainability} path="/sustainability" title={data.home.video2} mainStyleClassname="genericplayer-v2-bg" homeTarget="home" /> 
        <PlayerRoute videoNum={VideoNum.P1} path="/product-1" title={data.product_tour.p1} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" />
        <PlayerRoute videoNum={VideoNum.P2} path="/product-2" title={data.product_tour.p2} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" /> 
        <PlayerRoute videoNum={VideoNum.P3} path="/product-3" title={data.product_tour.p3} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" /> 
        <PlayerRoute videoNum={VideoNum.P4} path="/product-4" title={data.product_tour.p4} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" /> 
        <PlayerRoute videoNum={VideoNum.P5} path="/product-5" title={data.product_tour.p5} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" /> 
        <PlayerRoute videoNum={VideoNum.P6} path="/product-6" title={data.product_tour.p6} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" /> 
        <PlayerRoute videoNum={VideoNum.P7} path="/product-7" title={data.product_tour.p7} homeTarget="tour" mainStyleClassname="genericplayer-main-bg" /> 

        <Route path="/home" render={(props)=> <HomeScreen {...props} data={data} />} />
        <Redirect to='/home' />
      </Switch>
    </>
  );
} 
export default App;