import logo from '../assets/webasto-logo.svg';

const Header = ({data}) => {
  console.log(data)
  const slogan = data?.global?.slogan ||  ""
  return (
    <>
      <header className="header-logo shadow-lg flex">
        <div className="flex items-center">
          <div className="header-logo--wrap">
            <img width="150" alt="Webasto Logo" className="header-logo--image" src={logo} />
          </div>
          <div className="header-slogan">
            {/* <img src="slogan.svg" alt={slogan} width="433" height="42" /> */}
            {slogan}
          </div>
        </div>
      </header>
    </>
  )
};

export default Header;