import { useState } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

interface TourScreenProps {
  mainStyleClassname: string;
  history: any;
  data: any;
}

const TourScreen = (tourScreenProps: TourScreenProps): JSX.Element => {

  const { history, mainStyleClassname, data } = tourScreenProps;

  const [currentTour, setCurrentTour] = useState(0);

  const handleTour = (num: number) => {
    setCurrentTour(num);
    setTimeout(
      () => {
        history.push(`/product-${num}`);
      }, 1000
    );
  };

  if (!data) return <>Loading...</>

  return (
    <div className="container">
      <main className={mainStyleClassname}>
        <Header data={data} />
        <div className="panel" >
          <div className="panel-item"></div>
          {/* <div className="panel-item" >
            {
              [1, 2, 3, 4, 5, 6, 7].map((i) => <div onClick={() => handleTour(i)} className={`tour-map-button tour-map-button--${i}`}> {i}</div>)
            }
          </div> */}

          <div className="panel-item" >
            <div className="aside-menu--tour">
              <ul>{[1, 2, 3, 4, 5, 6, 7].map((i) => <li key={`tour-li-${i}`}>
                <button onClick={() => handleTour(i)} className={`${(currentTour === i) ? "tour-button tour-button--active" : (currentTour > 0 && currentTour !== i) ? "tour-button--invisible" : "tour-button"}`}> <div className="numberCircle">{i}</div> {data.product_tour[`p${i}`]} </button>
              </li>)}
              </ul>
            </div>
          </div>
        </div>
      </main>
      <Footer history={history} />
    </div>
  )
};

export default TourScreen;
