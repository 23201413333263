import React from 'react';

import home from '../assets/home.png';

const Footer = ({history, homeTarget='home'}) => {

  const handleHomeButton = () => {
    if (homeTarget === "idle") {
      homeTarget = "";
    }
    history.push(`/${homeTarget}`);
  };

  if (homeTarget === "idle") {
    return <></>
  }

  return (
    <footer><div className="flex-center">
        <img src={home} className="footer-home--image" alt="home" onClick={handleHomeButton}  />
      </div>
  </footer>
  )
};

export default Footer;