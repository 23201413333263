import React, { useRef } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { getVideoFilenameByIndex } from '../lib/utils';

type AnyProps = {
  [key: string]: any;
};

const GenericLocalPlayerScreen: React.FC<AnyProps> = (playerProps: AnyProps): JSX.Element => {

  const { title, history, mainStyleClassname, homeTarget, videoNum, data } = playerProps;

  const videoEl = useRef<HTMLVideoElement>(null);

  const handleCancel = () => {
    history.push(`/${homeTarget}`);
  };

  // const handleFullscreenReq = () => { videoEl?.current?.requestFullscreen() };

  const vid = getVideoFilenameByIndex(videoNum);

  return (
    <div className="container">
      <main className={mainStyleClassname}>
        <Header data={data} />
        <div className="panel" >
          <div className="panel-item"></div>
          <div className="panel-item genericlocalplayer--main" >
            <>
              <h2>{title}</h2>

              <div className="genericplayer--video">
                <video ref={videoEl} onEnded={handleCancel} src={require(`../assets/videos/${vid}`).default} autoPlay controls controlsList="nodownload" />
              </div>

              <button className="progress-cancel--rounded text--center"
                onClick={handleCancel}> x
              </button>
            </>
          </div>
        </div>
      </main>
      <Footer history={history} />
    </div>
  )
};

export default GenericLocalPlayerScreen;