import Header from '../components/Header';
import Footer from '../components/Footer';

const HomeScreen = ({ history, data }: CommonScreenProps): JSX.Element => {

  const handleSelect = (scene: string) => {
    history.push(`/${scene}`);
  };

  if (!data) return <>Loading...</>

  return (
    <div className="container">

      <main className="bg-home">
        <Header data={data} />
        <div className="panel ">

          <div className="panel-item">
          </div>

          <div className="panel-item home-menu--main">
            <div className="home-box">
              <div className="home-box--left">
                <button onClick={() => handleSelect("tour")} className="home-button home-button--cad1" >
                  <img src={require(`../assets/home/home-box-one.png`).default} alt="Tour" className="home-box--one invisible" />
                  <br />
                  <span className="home-box--headline">{data.home.product_tour}</span><br />
                  <span>{data.home.product_tour_sub}</span>
                </button>
              </div>
              <div className="home-box--right">
                <button onClick={() => handleSelect("global-production")} className="home-button home-button--cad2">
                  <img src={require(`../assets/home/home-box-two.png`).default} alt="global-production"  className="home-box--two invisible" />
                  <br />
                  <span>{data.home.video1}</span>
                </button>
                <br />
                <button onClick={() => handleSelect("sustainability")} className="home-button home-button--cad3" >
                  <img src={require(`../assets/home/home-box-three.png`).default} alt="sustainability"  className="home-box--two invisible" />
                  <br />
                  <span>{data.home.video2}</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </main>
      <Footer homeTarget='idle' history={history} />
    </div>
  )
};

export default HomeScreen;
